import axios from "axios";
import {useDraftStore} from "@/store/draft";
import {useTrackingStore} from "@/store/tracking";
import {useAspspStore} from "@/store/aspsp";
import {constants} from "@/constants";
import Logger from "@/services/logger";
import sha256 from 'sha256';

export default class Orion {
  static getApiBaseUrl() {
    if (process.env.VUE_APP_ORION_API_BASE_URL) {
      return process.env.VUE_APP_ORION_API_BASE_URL;
    }
    if (window.globalConfig.siteBaseUrl) {
      const siteBaseUrl = new URL(window.globalConfig.siteBaseUrl);
      const domainName = siteBaseUrl.host.split('.').slice(-2).join('.');
      return 'https://orionv2.' + domainName;
    }
  }

  static getOldApiBaseUrl() {
    return process.env.VUE_APP_OLD_ORION_API_BASE_URL;
  }

  static prepareOrder(
    cart,
    newOrder,
    orderExtraData = {},
    isOneClickCompleted = false,
  ) {
    const oldOrder = useDraftStore().order;
    const preparedOrder = Object.assign({}, oldOrder, newOrder, orderExtraData);

    if (newOrder.sameAsBilling) {
      this.copyBillingIntoShipping(preparedOrder);
    }

    const regexIdentifier = /^[a-zA-Z0-9]*$/;
    let walletId = null,
      sfId = null;
    if (
      useTrackingStore().urlParams.walletId &&
      regexIdentifier.test(useTrackingStore().urlParams.walletId)
    ) {
      walletId = useTrackingStore().urlParams.walletId;
    }
    if (
      useTrackingStore().urlParams.sfId &&
      regexIdentifier.test(useTrackingStore().urlParams.sfId)
    ) {
      sfId = useTrackingStore().urlParams.sfId;
    }

    preparedOrder.status =
      isOneClickCompleted || preparedOrder.orderType === "crosssell"
        ? "completed"
        : "pending"; // only palyance will change status to completed
    preparedOrder.url = window.location.href;
    preparedOrder.vanguardRevision = `shop-${process.env.VUE_APP_BUILD_DATE}`;
    preparedOrder.testLevel = useTrackingStore().urlParams.testLevel || null;
    preparedOrder.trackingCode =
      useTrackingStore().urlParams.trackingCode ||
      useTrackingStore().urlParams.source ||
      "XMISSING";
    preparedOrder.leadTrackingCode =
      useTrackingStore().urlParams.leadTrackingCode || "";
    preparedOrder.affiliatePicklist = window.globalConfig.affiliateId;
    preparedOrder.contactPolarisId = sfId;
    preparedOrder.currencyIsoCode = window.globalConfig.currencyIsoCode;
    preparedOrder.palyancePaymentId = useAspspStore().paymentIdentifier || "";
    preparedOrder.palyancePaymentAccountIdentifier =
      useAspspStore().paymentAccountIdentifier || null;
    preparedOrder.walletId = walletId;
    preparedOrder.aspsp =
      (useAspspStore().isAspspEligibleSepa &&
        useDraftStore().selectedPaymentMethod ===
          constants.PAYMENT_METHOD_SEPA) ||
      (useAspspStore().isAspspEligibleBankCard &&
        useDraftStore().selectedPaymentMethod ===
          constants.PAYMENT_METHOD_BANK_CARD);
    preparedOrder.paymentType = useDraftStore().selectedPaymentMethod;
    preparedOrder.emailContentId = window.globalConfig.emailContentId;
    preparedOrder.abandonEmailContentId =
      window.globalConfig.abandonEmailContentId;
    preparedOrder.formCode = "SHOP";
    preparedOrder.mindbazBase = useTrackingStore().urlParams.base || "";
    preparedOrder.mindbazCampaign =
      useTrackingStore().urlParams.campaignId || "";
    preparedOrder.mindbazShoot = useTrackingStore().urlParams.shootId || "";
    preparedOrder.mindbazSegment = useTrackingStore().urlParams.segmentId || "";
    preparedOrder.marketingCloudDataSourceName =
      useTrackingStore().urlParams.d || "";
    preparedOrder.marketingCloudJobId = useTrackingStore().urlParams.j || "";
    preparedOrder.marketingCloudListId = useTrackingStore().urlParams.l || "";
    preparedOrder.marketingCloudBatchId = useTrackingStore().urlParams.b || "";
    preparedOrder.marketingCloudSendDate =
      useTrackingStore().urlParams.senddate || "";
    preparedOrder.source = "shop";
    preparedOrder.lang = window.globalConfig.lang;
    preparedOrder.externalPartnerId =
      useTrackingStore().urlParams.externalPartnerId;
    preparedOrder.isMultipleChoice = true;
    preparedOrder.screenshotBdc = null;
    preparedOrder.isPalyanceEligible = useAspspStore().isEligible;
    preparedOrder.has1ClickOptOut = useDraftStore().has1ClickOptOut;
    preparedOrder.orderMethod = this.getOrderMethod(preparedOrder);
    preparedOrder.shippingPrice = cart.shippingPrice / 100;
    preparedOrder.queryStringParameters = useTrackingStore().urlParams;
    preparedOrder.useDataFromChoices = window.globalConfig.useDataFromChoices
      ? window.globalConfig.useDataFromChoices
      : false;

    //console.log(preparedOrder);

    return preparedOrder;
  }

  static needCopyBillingIntoShipping(order) {
    return (
      order.shippingGender !== order.billingGender ||
      order.shippingFirstName !== order.billingFirstName ||
      order.shippingLastName !== order.billingLastName ||
      order.shippingPhoneNumber !== order.billingPhoneNumber ||
      order.shippingStreet3 !== order.billingStreet3 ||
      order.shippingStreet4 !== order.billingStreet4 ||
      order.shippingStreet1 !== order.billingStreet1 ||
      order.shippingStreet2 !== order.billingStreet2 ||
      order.shippingZipCode !== order.billingZipCode ||
      order.shippingCity !== order.billingCity ||
      order.shippingCountry !== order.billingCountry
    );
  }

  static copyBillingIntoShipping(order) {
    order.shippingGender = order.billingGender;
    order.shippingFirstName = order.billingFirstName;
    order.shippingLastName = order.billingLastName;
    order.shippingPhoneNumber = order.billingPhoneNumber;
    order.shippingStreet3 = order.billingStreet3;
    order.shippingStreet4 = order.billingStreet4;
    order.shippingStreet1 = order.billingStreet1;
    order.shippingStreet2 = order.billingStreet2;
    order.shippingZipCode = order.billingZipCode;
    order.shippingCity = order.billingCity;
    order.shippingCountry = order.billingCountry;
  }

  static sendOrder(cart, order, domainId, actionLegacy = "create") {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.getApiBaseUrl()}/order`, {
          cart,
          order,
          domainId,
          actionLegacy,
        })
        .then(
          (response) => {
            console.log(response);
            Logger.log("sendOrder response", {
              cart: response.data.cart,
              order: {
                externalOrderId: response.data.order.externalOrderId,
                billingEmail: sha256(
                  response.data.order.billingEmail.toLowerCase(),
                ),
                trackingCode: response.data.order.trackingCode,
                testLevel: response.data.order.testLevel,
                queryStringParameters:
                  response.data.order.queryStringParameters,
              },
              status: response.status,
              actionLegacy: actionLegacy,
            });
            resolve(response);
          },
          (error) => {
            console.error(error);
            Logger.error("sendOrder error", error);
            reject(error);
          },
        );
    });
  }

  static updateContactOnOrder(contact) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.getApiBaseUrl()}/order`, {
          contact,
        })
        .then(
          (response) => {
            console.log(response);
            Logger.log("updateContactOnOrder response", response);
            resolve(response);
          },
          (error) => {
            console.error(error);
            Logger.log("updateContactOnOrder error", error);
            reject(error);
          },
        );
    });
  }

  static is1ClickLinkAlreadyUsed() {
    return new Promise((resolve, reject) => {
      const url = `${this.getOldApiBaseUrl()}/alreadyclicked`;
      const params = {
        trackingCode: useTrackingStore().urlParams.trackingCode,
        sfId: useTrackingStore().urlParams.sfId,
        formCode: `SHOP-${window.globalConfig.domainId}`,
      };
      axios.get(url, { params }).then(
        (response) => {
          console.log(response);
          Logger.log("is1ClickLinkAlreadyUsed response", response);
          resolve(response.data);
        },
        (error) => {
          console.error(error);
          Logger.log("is1ClickLinkAlreadyUsed error", error);
          reject(error);
        },
      );
    });
  }
  static getOrderMethod(order) {
    // Priority 1: Fallback 1-click
    if (order.externalPartnerId === 'fallback1c') {
      return 'fallback_oneclick';
    }

    // Priority 2: Simple prefill
    if (useAspspStore().prefillData && useAspspStore().prefillData.EMAIL && !order.walletId) {
      return 'prefill';
    }

    // Priority 3: Credit Card prefill
    if (useAspspStore().prefillData && order.walletId && order.palyancePaymentAccountIdentifier && 
        order.paymentType === constants.PAYMENT_METHOD_BANK_CARD) {
      return 'palyance_prefill_cc';
    }

    // Priority 4: Palyance SEPA
    if (order.paymentType === constants.PAYMENT_METHOD_SEPA && order.walletId) {
      if (useAspspStore().aspspPaymentInformations?.sepa?.doubleOptin) {
        return 'palyance_with_validation';
      }
      return 'palyance_without_validation';
    }

    // Priority 5: 1-click
    if (useDraftStore().isEligibleToOneClick && window.globalConfig.authorized1click) {
      return 'oneclick';
    }

    // Default case
    return 'payment_form';
  }

}
